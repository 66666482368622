import * as MediaPreviewType from '../constants/MediaPreviewType';

export const getSupportedEmbedUrlMediaPreviewType = (url: string) => {
  if (!url) {
    return MediaPreviewType.None;
  }
  if (url.startsWith('https://www.youtube.com/shorts')) {
    return MediaPreviewType.EmbedYoutubeShort;
  }
  if (url.startsWith('https://youtube.com/shorts')) {
    return MediaPreviewType.EmbedYoutubeShort;
  }
  if (url.startsWith('youtube.com/shorts')) {
    return MediaPreviewType.EmbedYoutubeShort;
  }
  if (url.startsWith('youtube.com')) {
    return MediaPreviewType.EmbedYoutube;
  }
  if (url.startsWith('https://youtube.com')) {
    return MediaPreviewType.EmbedYoutube;
  }
  if (url.startsWith('https://youtu.be')) {
    return MediaPreviewType.EmbedYoutube;
  }
  if (url.startsWith('https://www.youtube.com')) {
    return MediaPreviewType.EmbedYoutube;
  }
  if (url.startsWith('youtube-nocookie.com')) {
    return MediaPreviewType.EmbedYoutubeNoCookie;
  }
  if (url.startsWith('https://youtube-nocookie.com')) {
    return MediaPreviewType.EmbedYoutubeNoCookie;
  }
  if (url.startsWith('https://www.youtube-nocookie.com')) {
    return MediaPreviewType.EmbedYoutubeNoCookie;
  }

  if (url.startsWith('instagram.com')) {
    return MediaPreviewType.EmbedInstagram;
  }
  if (url.startsWith('https://instagram.com')) {
    return MediaPreviewType.EmbedInstagram;
  }
  // broken
  // if (url.startsWith('https://www.instagram.com')) {
  //   return MediaPreviewType.EmbedInstagram;
  // }
  if (url.startsWith('soundcloud.com')) {
    return MediaPreviewType.EmbedSoundcloud;
  }
  if (url.startsWith('https://soundcloud.com')) {
    return MediaPreviewType.EmbedSoundcloud;
  }
  if (url.startsWith('twitter.com')) {
    return MediaPreviewType.EmbedTwitter;
  }
  if (url.startsWith('https://twitter.com')) {
    return MediaPreviewType.EmbedTwitter;
  }
  if (url.startsWith('https://www.google.com/maps')) {
    return MediaPreviewType.EmbedGoogleMaps;
  }
  if (url.startsWith('https://vimeo.com/')) {
    return MediaPreviewType.EmbedVimeo;
  }
  if (url.endsWith('.pdf')) {
    return MediaPreviewType.EmbedPdf;
  }
  return MediaPreviewType.None;
};
