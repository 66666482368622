import { omit } from 'lodash-es';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CardModal from '../components/CardModal';
import { cardColors } from '../constants/CardColors';
import Flags from '../constants/Flags.js';
import * as entryActions from '../entry-actions';
import { push } from '../lib/redux-router';
import * as selectors from '../selectors';
import { selectCardPermissionsForCurrentCardAndUser } from '../selectors';
import { createCurrentDateWithoutSeconds } from '../utils/createCurrentDate';
import { getFinalMediaPreviewUrl } from '../utils/getFinalMediaPreviewUrl';

const mapStateToProps = (state) => {
  const { projectId } = selectors.selectPath(state);
  const {
    name,
    description,
    boardId,
    listId,
    embedUrl,
    ogTitle,
    ogDescription,
    ogImage,
    mediaUrl,
    backgroundColor,
    scheduledToBeVisibleAt,
    coverUrl,
    mediaPreviewUrl,
    isGuestCard,
    ready,
    isCreator,
    defaultPreviewDeleted,
  } = selectors.selectCurrentCard(state);
  const project = selectors.selectCurrentProject(state);

  const attachments = selectors.selectAttachmentsForCurrentCard(state);
  const { canEditCard, canScheduleOrDuplicateCard } =
    selectCardPermissionsForCurrentCardAndUser(state);
  const {
    ui: {
      cardModalForm: { error, isMediaUpdating },
    },
  } = state;

  let previewImageId = '';
  let attachmentId = '';
  const customImageUpload = Flags.CUSTOM_IMAGE_UPLOAD;

  if (attachments.length === 2) {
    // the first attachment is the preview image as it is newer
    previewImageId = attachments[0].id;
    attachmentId = attachments[1].id;
  } else if (attachments.length === 1) {
    const isEmbed = !!embedUrl;
    if (isEmbed) {
      previewImageId = attachments[0].id;
    } else {
      attachmentId = attachments[0].id;
    }
  }

  const backgroundColors = cardColors.map((color) => color.value);
  const fallbackBackgroundColor = cardColors.find((color) => color.name === 'white').value;
  const actualBackgroundColor = backgroundColor || fallbackBackgroundColor;
  const showCalendar = Flags.CARD_SCHEDULING && canScheduleOrDuplicateCard;

  const dateTimeDate = scheduledToBeVisibleAt
    ? new Date(scheduledToBeVisibleAt)
    : createCurrentDateWithoutSeconds();
  const dateTime = new Date(dateTimeDate.getTime() - dateTimeDate.getTimezoneOffset() * 60000)
    .toISOString()
    .slice(0, -1);

  const showDuplicate = Flags.CARD_DUPLICATE && canScheduleOrDuplicateCard;
  const descriptionFocusViaTab = Flags.CARD_DESCRIPTION_FOCUS_VIA_TAB;

  const finalMediaPreviewUrl = getFinalMediaPreviewUrl({
    customImageUpload,
    mediaPreviewUrl,
    coverUrl,
    mediaUrl,
  });

  const boardWriteAccess = project.writeAccess;

  return {
    isGuestCard,
    ready,
    name,
    description,
    listId,
    boardId,
    projectId,
    attachments,
    canEdit: canEditCard,
    error,
    embedUrl,
    mediaUrl,
    attachmentId,
    previewImageId,
    ogTitle,
    ogDescription,
    ogImage,
    isMediaUpdating,
    backgroundColor: actualBackgroundColor,
    backgroundColors,
    showCalendar,
    dateTime,
    showDuplicate,
    mediaPreviewUrl: finalMediaPreviewUrl,
    mediaLinkUrl: mediaUrl,
    descriptionFocusViaTab,
    customImageUpload,
    boardWriteAccess,
    isCreator,
    defaultPreviewDeleted,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onUpdate: entryActions.updateCurrentCard,
      onAttachmentCreate: entryActions.createAttachmentInCurrentCard,
      onAttachmentUpdate: entryActions.updateAttachment,
      onAttachmentDelete: entryActions.deleteAttachmentInCurrentCard,
      onClose: entryActions.cardModalClose,
      push,
      openCardDuplicateModal: entryActions.openCardDuplicateModal,
      onDescriptionEditConfirmModalOpen: entryActions.openDescriptionEditConfirmModal,
      handlePreviewFileSelect: entryActions.createPreviewImageInCurrentCard,
      handlePreviewFileDelete: entryActions.deletePreviewImageInCurrentCard,
    },
    dispatch,
  );

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...omit(dispatchProps, 'push'),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CardModal);
