/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
import classNames from 'classnames';
import React, { useCallback, useRef } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import ActionsStep from './ActionsStep.jsx';
import styles from './Card.module.scss';
import NameEdit from './NameEdit';
import * as CardApprovedType from '../../constants/CardApprovedType.js';
import * as MediaPreviewType from '../../constants/MediaPreviewType';
import DefaultMediaPreviewContainer from '../../containers/MediaPreviewContainer.js';
import { usePopup } from '../../lib/popup';
import ContentPreview from '../ContentPreview/ContentPreview.js';

const getHeightOfIframe = (mediaPreviewType: number) => {
  if (mediaPreviewType === MediaPreviewType.EmbedYoutubeShort) {
    return 450;
  }
  return 136;
};

const getGuestCardText = (t: any, approvedBoolean: boolean, ready: boolean): string => {
  if (approvedBoolean) {
    return t('common.guestCard');
  }
  if (ready) {
    return t('common.guestCard');
  }
  return t('common.guestCardNotReady');
};

function Card({
  id,
  index,
  name,
  ready,
  description,
  boardId,
  listId,
  projectId,
  isPersisted,
  allProjectsToLists,
  allBoardMemberships,
  canEdit,
  onUpdate,
  onMove,
  onTransfer,
  onDelete,
  onBoardFetch,
  onApprove,
  onReject,
  ogImage,
  ogTitle,
  ogDescription,
  mediaLinkUrl,
  openMediaPreviewModal,
  backgroundColor,
  mediaPreviewUrl,
  scheduledToBeVisible,
  MediaPreviewContainer = DefaultMediaPreviewContainer,
  customImageUpload,
  embedUrl,
  mediaUrl,
  isGuestCard,
  canApproveOrRejectCard,
  approved,
  canMarkAsReady,
  approved2,
  defaultPreviewDeleted,
}: {
  ogImage?: string;
  ogTitle?: string;
  ogDescription?: string;
  id: string;
  index: number;
  name: string;
  ready?: boolean;
  description?: string;
  boardId: string;
  listId: string;
  projectId: string;
  isPersisted: boolean;
  allProjectsToLists: readonly any[];
  allBoardMemberships: readonly any[];
  /* eslint-enable react/forbid-prop-types */
  canEdit: boolean;
  onUpdate: any;
  onMove: any;
  onTransfer: any;
  onDelete: any;
  onBoardFetch: any;
  onApprove: any;
  onReject: any;
  embedUrl?: string;
  mediaUrl?: string;
  mediaPreviewUrl?: string;
  openMediaPreviewModal?: () => void;
  backgroundColor?: string;
  mediaLinkUrl?: string;
  scheduledToBeVisible: boolean;
  MediaPreviewContainer?: any;
  customImageUpload: boolean;
  isGuestCard: boolean;
  canApproveOrRejectCard: boolean;
  canMarkAsReady?: boolean;
  approved?: any;
  approved2?: any;
  defaultPreviewDeleted?: boolean;
}) {
  const [t] = useTranslation();
  const nameEdit = useRef(null);

  const handleNameUpdate = useCallback(
    (newName) => {
      onUpdate({ name: newName });
    },
    [onUpdate],
  );

  const handleNameEdit = useCallback(() => {
    nameEdit.current.open();
  }, []);

  const handleApproveUpdate = useCallback(() => {
    onUpdate({ approved2: CardApprovedType.ApprovedByOwner });
  }, [onUpdate]);

  const setReady = useCallback(() => {
    onUpdate({ ready: true });
  }, [onUpdate]);

  const ActionsPopup = usePopup(ActionsStep);

  const approvedBoolean =
    approved === undefined ||
    approved === true ||
    approved === CardApprovedType.AutoApproved ||
    approved === CardApprovedType.ApprovedByOwner ||
    approved2 === CardApprovedType.AutoApproved ||
    approved2 === CardApprovedType.ApprovedByOwner;

  const guestCardText = getGuestCardText(t, approvedBoolean, ready);

  const contentNode = (
    <>
      {isGuestCard && <div className="text-xs text-black mb-5">{guestCardText}</div>}
      <div className={classNames(!approvedBoolean && 'opacity-30')}>
        <div className={classNames(styles.name, 'text-lg text-blue100 font-medium pb-4')}>
          {name}
        </div>
        <MediaPreviewContainer
          url={mediaPreviewUrl}
          ogTitle={ogTitle}
          ogDescription={ogDescription}
          ogImage={ogImage}
          getHeightOfIframe={getHeightOfIframe}
          onClick={openMediaPreviewModal}
          mediaLinkUrl={mediaLinkUrl}
          customImageUpload={customImageUpload}
          mediaUrl={mediaUrl}
          mediaPreviewUrl={mediaPreviewUrl}
          embedUrl={embedUrl}
          media={mediaUrl}
          defaultPreviewDeleted={defaultPreviewDeleted}
        />
        <div className="mt-3" />
        <div className={styles.details}>
          {description && <ContentPreview value={description} />}
        </div>
      </div>
      {isGuestCard && canApproveOrRejectCard && ready && (
        <div>
          <div className="text-black text-xs mt-10">{t('common.approveGuestCard')}</div>
          <div className="flex justify-between gap-4 mt-2">
            <button
              type="button"
              className={classNames(
                'w-full flex justify-center gap-2 items-center border border-solid border-blue10 h-8 relative bg-transparent hover:bg-blue10 text-black text-xs rounded-sm',
              )}
              onClick={onReject}
              data-test-id="RejectCard"
            >
              <img src="/assets/images/icon-reject.svg" className="h-5 w-5 z-10" alt="" />
              {t('action.rejectCard')}
            </button>
            <button
              type="button"
              className={classNames(
                'w-full flex justify-center gap-2 items-center border border-solid border-blue10 h-8 relative bg-transparent hover:bg-blue10 text-black text-xs rounded-sm',
              )}
              onClick={handleApproveUpdate}
              data-test-id="ApproveCard"
            >
              <img src="/assets/images/icon-approve.svg" className="h-5 w-5 z-10" alt="" />
              {t('action.approveCard')}
            </button>
          </div>
        </div>
      )}
    </>
  );

  return (
    // @ts-ignore
    <Draggable draggableId={`card:${id}`} index={index} isDragDisabled={!isPersisted || !canEdit}>
      {({ innerRef, draggableProps, dragHandleProps }) => (
        <div
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...draggableProps}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...dragHandleProps}
          ref={innerRef}
          className={classNames(styles.wrapper, 'mb-4 rounded-sm')}
          data-test-id="Card"
        >
          {/* @ts-ignore */}
          <NameEdit ref={nameEdit} defaultValue={name} onUpdate={handleNameUpdate}>
            <div className={styles.card} style={{ backgroundColor }}>
              {isPersisted ? (
                <>
                  <div className={styles.content}>{contentNode}</div>
                  {canEdit && (
                    <ActionsPopup
                      // @ts-ignore
                      card={{ boardId, listId, projectId, id }}
                      projectsToLists={allProjectsToLists}
                      boardMemberships={allBoardMemberships}
                      onNameEdit={handleNameEdit}
                      onUpdate={onUpdate}
                      onMove={onMove}
                      onTransfer={onTransfer}
                      onDelete={onDelete}
                      onBoardFetch={onBoardFetch}
                      canMarkAsReady={canMarkAsReady}
                      setReady={setReady}
                    >
                      <Button
                        className={classNames(styles.actionsButton, styles.target, 'top-3 right-2')}
                      >
                        <img src="/assets/images/icon-dots-blue.svg" alt="" className="w-4 h-4" />
                      </Button>
                    </ActionsPopup>
                  )}
                  {scheduledToBeVisible && (
                    <div className="absolute top-1 right-1">
                      <img src="/assets/images/icon-clock.svg" alt="" className="w-4 h-4" />
                    </div>
                  )}
                </>
              ) : (
                <span className={styles.content}>{contentNode}</span>
              )}
            </div>
          </NameEdit>
        </div>
      )}
    </Draggable>
  );
}

export default React.memo(Card);
