import classnames from 'classnames';
import PropTypes, { InferProps } from 'prop-types';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Popup as SemanticUIPopup } from 'semantic-ui-react';
import styles from './Popup.module.css';

export default <StepType extends React.FunctionComponent>(
  Step: StepType,
  props?: React.ComponentProps<StepType>,
  classNames?: string,
) => {
  return useMemo(() => {
    function Popup({
      children,
      onClose,
      ...stepProps
    }: InferProps<typeof Popup.propTypes> & React.ComponentProps<StepType>) {
      const [isOpened, setIsOpened] = useState(false);

      const wrapper = useRef(null);
      const resizeObserver = useRef(null);

      const handleOpen = useCallback(() => {
        setIsOpened(true);
      }, []);

      const handleClose = useCallback(() => {
        setIsOpened(false);

        if (onClose) {
          onClose();
        }
      }, [onClose]);

      const handleMouseDown = useCallback((event) => {
        event.stopPropagation();
      }, []);

      const handleClick = useCallback((event) => {
        event.stopPropagation();
      }, []);

      const handleTriggerClick = useCallback(
        (event) => {
          event.preventDefault();
          event.stopPropagation();

          // This reads somewhat strange but this is how it was found in the upstream planka code
          // @ts-ignore
          const { onClick } = children;

          if (onClick) {
            onClick(event);
          }
        },
        [children],
      );

      const handleContentRef = useCallback((element) => {
        if (resizeObserver.current) {
          resizeObserver.current.disconnect();
        }

        if (!element) {
          resizeObserver.current = null;
          return;
        }

        resizeObserver.current = new ResizeObserver(() => {
          if (resizeObserver.current.isInitial) {
            resizeObserver.current.isInitial = false;
            return;
          }

          wrapper.current.positionUpdate();
        });

        resizeObserver.current.isInitial = true;
        resizeObserver.current.observe(element);
      }, []);

      const trigger = React.cloneElement(children, {
        // @ts-ignore
        onClick: handleTriggerClick,
      });

      return (
        <SemanticUIPopup
          basic
          wide
          ref={wrapper}
          trigger={trigger}
          closeOnEscape
          on="click"
          open={isOpened}
          position="bottom left"
          popperModifiers={[
            { name: 'preventOverflow', enabled: true, options: { altAxis: true, padding: 20 } },
          ]}
          className={classnames(styles.wrapper, classNames)}
          onOpen={handleOpen}
          onClose={handleClose}
          onMouseDown={handleMouseDown}
          onClick={handleClick}
          {...props} // eslint-disable-line react/jsx-props-no-spreading
        >
          <div ref={handleContentRef}>
            {/* @ts-ignore */}
            <Step
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...stepProps}
              onClose={handleClose}
            />
          </div>
        </SemanticUIPopup>
      );
    }

    Popup.propTypes = { children: PropTypes.element.isRequired, onClose: PropTypes.func };

    Popup.defaultProps = { onClose: undefined };

    return React.memo(Popup);
  }, [Step, classNames, props]);
};
