export const getFinalMediaPreviewUrl = ({
  customImageUpload,
  mediaPreviewUrl,
  coverUrl,
  mediaUrl,
}: {
  customImageUpload: boolean;
  mediaPreviewUrl: string;
  coverUrl: string;
  mediaUrl: string;
}): string => {
  // if (isImage(mediaUrl)) {
  //   return undefined;
  // }
  const finalMediaPreviewUrl = customImageUpload
    ? mediaPreviewUrl || coverUrl
    : coverUrl || mediaUrl;
  return finalMediaPreviewUrl;
};
