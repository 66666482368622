import { all, takeEvery } from 'redux-saga/effects';
import EntryActionTypes from '../../../constants/EntryActionTypes';
import services from '../services';

type ActionType = { payload: any; type: string };

export default function* projectsWatchers() {
  yield all([
    takeEvery(EntryActionTypes.PROJECT_CREATE, ({ payload: { data } }: ActionType) =>
      services.createProject(data),
    ),
    takeEvery(EntryActionTypes.PROJECT_CREATE_HANDLE, ({ payload: { project } }: ActionType) =>
      services.handleProjectCreate(project),
    ),
    takeEvery(
      EntryActionTypes.CURRENT_PROJECT_UPDATE,
      ({ payload: { data, redirectTo } }: ActionType) =>
        services.updateCurrentProject(data, redirectTo),
    ),
    takeEvery(
      EntryActionTypes.PROJECT_UPDATE_HANDLE,
      ({ payload: { project, projectProfessions } }: ActionType) =>
        services.handleProjectUpdate(project, projectProfessions),
    ),
    takeEvery(
      EntryActionTypes.CURRENT_PROJECT_BACKGROUND_IMAGE_UPDATE,
      ({ payload: { data } }: ActionType) => services.updateCurrentProjectBackgroundImage(data),
    ),
    takeEvery(
      EntryActionTypes.CURRENT_PROJECT_THUMBNAIL_UPDATE,
      ({ payload: { data } }: ActionType) => services.updateCurrentProjectThumbnail(data),
    ),
    takeEvery(
      EntryActionTypes.CURRENT_PROJECT_THUMBNAIL_DELETE,
      ({ payload: { data } }: ActionType) => services.deleteCurrentProjectThumbnail(data),
    ),
    takeEvery(EntryActionTypes.CURRENT_PROJECT_DELETE, () => services.deleteCurrentProject()),
    takeEvery(
      EntryActionTypes.PROJECT_PASSWORD_CHANGE_HANDLE,
      ({ payload: { projectId } }: ActionType) => services.handleProjectPasswordChange(projectId),
    ),
    takeEvery(EntryActionTypes.CURRENT_PROJECT_SCHEDULE_FOR_DELETION, () =>
      services.scheduleCurrentProjectForDeletion(),
    ),
    takeEvery(EntryActionTypes.PROJECT_DELETE_HANDLE, ({ payload: { project } }: ActionType) =>
      services.handleProjectDelete(project),
    ),
    takeEvery(
      EntryActionTypes.PROJECT_FILTER_UPDATE,
      ({ payload: { professionIds, sortOrder, page } }: ActionType) =>
        services.projectFilterUpdate(professionIds, sortOrder, page),
    ),
    takeEvery(
      EntryActionTypes.LOAD_COMMUNITY_PROJECTS,
      ({ payload: { page, professionIds, sortOrder } }: ActionType) =>
        services.loadCommunityProjects(page, professionIds, sortOrder),
    ),
    takeEvery(EntryActionTypes.CURRENT_PROJECT_DUPLICATE, ({ payload: { data } }: ActionType) =>
      services.duplicateCurrentProject(data),
    ),
    takeEvery(EntryActionTypes.PROJECT_IMPORT_FROM_PADLET, ({ payload: { data } }: ActionType) =>
      services.importProjectFromPadlet(data),
    ),
    takeEvery(EntryActionTypes.PROJECT_IMPORT_CANCEL, ({ payload: { data } }: ActionType) =>
      services.importProjectCancel(data),
    ),
    takeEvery(EntryActionTypes.PROJECT_BOOKMARK_CREATE, ({ payload: { projectId } }: ActionType) =>
      services.createBookmark(projectId),
    ),
    takeEvery(EntryActionTypes.PROJECT_BOOKMARK_DELETE, ({ payload: { projectId } }: ActionType) =>
      services.deleteBookmark(projectId),
    ),
    takeEvery(
      EntryActionTypes.PROJECT_TRY_ACCESS_PASSWORD,
      ({ payload: { url, projectId, plainTextPassword } }: ActionType) =>
        services.tryAccessPassword(url, projectId, plainTextPassword),
    ),
    takeEvery(EntryActionTypes.PROJECT_GET_SCHEDULED_TO_BE_DELETED, () =>
      services.getScheduledToBeDeletedProjects(),
    ),
    takeEvery(EntryActionTypes.PROJECT_RESTORE_FROM_TRASH, ({ payload: { id } }: ActionType) =>
      services.restoreProjectFromTrash(id),
    ),
    takeEvery(EntryActionTypes.PROJECT_DELETE, ({ payload: { id } }: ActionType) =>
      services.deleteProject(id),
    ),
  ]);
}
