export default {
  format: {
    date: 'd.MM.yyyy',
    time: 'p',
    dateTime: '$t(format:date) $t(format:time)',
    longDate: 'd. MMM',
    longDateTime: "d. MMMM 'um' p",
  },

  translation: {
    common: {
      markCardReady: 'Zur Freigabe senden',
      approveGuestCard: 'Gastbeitrag freigeben?',
      guestCard: 'Gastbeitrag',
      guestCardNotReady: 'Gastbeitrag (in Bearbeitung)',
      removeFile: 'Datei entfernen',
      removeReference: 'URL entfernen',
      changePreviewPicture: 'Vorschaubild ersetzen',
      newBoardIsPublicPrefix:
        'Boards werden standardmäßig öffentlich gestellt. Dies können Sie jederzeit über die Freigabeeinstellungen',
      newBoardIsPublicPostfix: ' ändern.',
      boardIsScheduledForDeletion: 'Das Board existiert nicht mehr.',
      boardIsScheduledForDeletionDetail: 'Das Board existiert nicht mehr, weil es gelöscht wurde.',
      textChangesAreNotSaved: 'Textänderungen noch nicht gespeichert',
      cancel: 'Abbrechen',
      areYouSureYouWantToDiscardChanges: `Bist du sicher, dass du deine Änderungen verwerfen und diese Kachel schließen möchtest?`,
      discardAndClose: 'Verwerfen & schließen',
      trackingOptOut: `Tracking Opt Out`,
      hintThisWidgetIsExternal: `Hinweis: Dieses Widget wird durch den externen Dienst %s bereitgestellt. Bei Nutzung dieses Dienstes erfolgt eine Datenübertragung an diesen Drittanbieter.`,
      forMoreInformationSee: `Weitere Hinweise hierzu entnehmen Sie bitte unserer`,
      dataPrivacySection: 'Datenschutzerklärung',
      allowAccessFor: `Zugriff auf %s erlauben`,
      allowAccessForAll: `Zugriff auf alle externen Drittanbieter erlauben.`,
      choose: 'Auswählen',
      areYouSureYouWantToDeleteThisBoardAsAdmin:
        'Sind Sie sicher, dass Sie dieses Board löschen möchten? In Ihrer Rolle als Admin wird dieses Board unwiderruflich gelöscht.',
      trashDeletedAfter14Days: 'Boards im Papierkorb werden automatisch nach 14 Tagen gelöscht.',
      exportProject: 'Projekt exportieren',
      sourceCode: 'Source Code',
      downloadSourceCode: 'Source Code herunterladen',
      noTrashProjectsInTrash: 'Es liegen keine Boards im Papierkorb.',
      save: 'Speichern',
      reset: 'Zurücksetzen',
      pickADate: 'Datum auswählen:',
      duplicate: 'Duplizieren',
      schedule: 'Datum/Uhrzeit einstellen',
      pleaseCheckBoardHint:
        'Bitte kontrolliere das importierte Board auf Vollständigkeit. In Padlet eingebundene Anhänge werden nicht importiert und müssen nachträglich händisch in MIKA-Do eingepflegt werden.',
      showScrollChange: 'Scrollmodus',
      targetProject: 'Ziel-Board',
      copy: 'Kopieren',
      duplicateCard: 'Kachel duplizieren',
      trash: 'Papierkorb',
      chooseBoardColor: 'Board Hintergrundfarbe',
      chooseCardColor: 'Kachel Hintergrundfarbe',
      oopsError: 'Ups, da ist etwas schief gegangen!',
      disableWebsockets: 'WebSockets deaktivieren',
      connectionError:
        'Verbindungsfehler. Bitte überprüfe deine Internetverbinding oder deaktiviere WebSockets',
      internalServerError: 'Interner Server Fehler',
      logOutTitle: 'Abmelden',
      thirdPartyNotices: 'Lizenzen',
      mostFavorited: 'Auf häufigsten gemerkt',
      mostForked: 'Am meisten geklont',
      createdDescending: 'Erstellungsdatum absteigend',
      updatedDescending: 'Änderungsdatum absteigend',
      sortOrder: 'Sortierung',
      passwordRequired: 'Falsches Passwort. Bitte richtiges Passwort eingeben.',
      toBoard: 'Zum Board',
      savedBoards: 'Gemerkte Boards',
      padletBoardImportFailure: `Leider gab es ein Problem beim Import: Bitte wenden Sie sich an mika@bibb.de`,
      padletUrlNotFound: '404: Padlet Board-Url nicht gefunden',
      invalidPadletUrl: `Ungültige Padlet Board-Url. Die Url sollte diesem Schema folgen: https://padlet.com/<Nutzername>/<Board-Titel-mit-ID>`,
      myFavorites: 'Meine Favoriten',
      dontBookmarkBoard: 'Board nicht mehr merken',
      bookmarkBoard: 'Board merken',
      boardImport: 'Board Importieren',
      boardImportFailed: 'Der Board Import ist fehlgeschlagen',
      padletBoardImport: 'Padlet Board Import',
      padletBoardUrl: 'Padlet Board Url',
      boardIsBeingImported: 'Board wird importiert...',
      presentTooltip: 'Präsentieren',
      present: 'Präsentieren',
      changePermissionTooltip: 'Freigabeeinstellungen',
      changePermission: 'Freigabeeinstellungen',
      startImport: 'Import starten',
      startImportAgain: 'Import erneut starten',
      cancelImport: 'Import abbrechen',
      importSuccessful: 'Board Import wurde erfolgreich abgeschlossen.',
      youCanNowEditBoard: 'Du kannst Dein Board nun in MIKA-Do weiter bearbeiten.',
      hereIsYourBoard: 'Hier geht es zu Deinem Board',
      boardIsNotPublic: 'Dieses Board ist nicht öffentlich.',
      canAskAdminToMakeBoardPublicOrPassword: `Wenn Du diesen Link vom Besitzer des Boards erhalten hast, kannst Du sie oder ihn bitten, das Board öffentlich zu machen oder einen Passwortschutz für dieses Board zu setzen`,
      pageNotFound: 'Die von Ihnen gesuchte Seite konnte nicht gefunden werden.',
      pageNotFoundPleaseContactSupportThanks: `Wenn Sie glauben, dass es sich um einen Fehler handelt, wenden Sie sich bitte an unser Support-Team, um Hilfe zu erhalten. Wir danken Ihnen!`,
      homePage: 'Startseite',
      boardIsPasswordProtected: 'Das Board ist passwortgeschützt',
      password: 'Passwort',
      copyProjectSuffix: 'Kopie',
      searchResults: 'Suchergebnisse',
      matches: 'Treffer',
      welcomeSubtitle1:
        'Sie wollen Arbeitsprozesse oder Lern- und Arbeitsaufgaben für Ihren Ausbildungsberuf digital abbilden? Und diese mit digitalen Medien anreichern und Ihren Auszubildenden zur Verfügung stellen? Dann legen Sie doch gleich los und erstellen Sie Ihr erstes Board mit MIKA-Do®!',
      welcomeSubtitle2: 'MIKA-Do® ist eine digitale Pinnwand für Lern- und Arbeitsaufgaben.',
      welcomeSubtitle3:
        'Hier finden Sie freie Bildungsmaterialien (Open Educational Resources) von Fachkräften für Fachkräfte.',
      welcomeQuestion1: 'Wieso überhaupt MIKA-Do®?',
      welcomeAnswer1:
        'Wissen wird nicht weniger, wenn wir es teilen. Nutzen Sie MIKA-Do® und die Expertise unserer Community für Ihre Ausbildung. Datenschutzkonform. Kostenfrei. Vielleicht ist schon ein Board für Sie dabei oder Sie legen selber los!',
      welcomeQuestion2: 'Was kann MIKA-Do®?',
      welcomeAnswer2:
        'Suchen und erstellen Sie Inhalte für Ihre Ausbildung. Strukturieren und organisieren Sie digital Lern- und Arbeitsaufgaben. Präsentieren und teilen Sie Ihr MIKA-Do mit Auszubildenden oder anderen Ausbilderinnen und Ausbildern.',
      welcomeQuestion3: 'Für wen ist MIKA-Do®?',
      welcomeAnswer3:
        'Betriebliches Ausbildungspersonal, Lehrkräfte an beruflichen Schulen und Auszubildende.',
      welcomeToMikado: 'Herzlich willkommen zu MIKA-Do®',
      enterNewBoardTitle: 'Bitte geben Sie einen Titel für das neue Board an',
      duplicateBoard: 'Board duplizieren',
      insertUrl: 'URL einfügen',
      ok: 'OK',
      pleaseEnterPassword: 'Bitte geben Sie das Passwort ein',
      protectedBoard: 'Geschützes Board',
      aboutMikado: 'Über MIKA-Do',
      version: 'Version',
      licenses: 'Lizenzen',
      badImage: 'Bildformat nicht erkannt.',
      search: 'Suche',
      delete: 'Löschen',
      deleteEmbed: 'Löschen',
      add: 'Hinzufügen',
      usersWithWriteAccess: 'Nutzer/-innen mit Schreibberechtigung',
      addLeandoSsoUsersWithWriteAccess:
        'Leando SSO-Nutzer/-innen mit Schreibberechtigung hinzufügen',
      deleteFile: 'Datei löschen',
      close: 'Schließen',
      uploadFile: 'Datei hochladen',
      uploadImage: 'Bild hochladen',
      uploadImageDescription:
        'Hier können Sie ein Bild hochladen, das als Vorschau dient und beim Anklicken die verknüpfte Datei oder Webseite öffnet.',
      uploadBoardImageDescription: 'Hier können Sie ein Bild hochladen, das als Vorschau dient.',
      fileTooBig:
        'Diese Datei ist zu groß für den Upload. Bitte verkleinern Sie die Datei auf 20MB.',
      invalidFile: 'Diese Datei ist nicht gültig.',
      uploadFileDescription:
        'Es können Dateien (z.B. Bilder, PDFs und Videos) mit einer maximalen Größe von 20 MB hochgeladen werden.',
      dragAndDrop: 'Drag-and-Drop',
      or: 'oder',
      imageShouldBe16x9: 'Das Bild sollte in etwa ein Seitenverhältnis von (BxH) 16:9 haben.',
      cardEdit: 'Kachel bearbeiten',
      thumbnail: 'Thumbnail',
      noResults: 'Keine Ergebnisse gefunden',
      noSearchResults: 'Leider konnten keine Suchergebnisse gefunden werden',
      professionsRequired:
        'Bitte wählen Sie mind. 1 Ausbildungsberuf aus, dem dieses Board zugeordnet werden kann',
      tooManyInvitesInBatch: 'Sie können maximal 10 Einladungen auf einmal versenden.',
      tooManyInvitesInHour:
        'Sie können maximal 60 Einladungen pro Stunde versenden. Bitte versuchen Sie es später erneut.',
      invalidWriteAccess:
        'Sie können anonymen Zugriff nur für öffentliche Boards und passwortgeschützte Boards aktivieren.',
      back: 'Zurück',
      error: 'Fehler',
      account: 'Konto',
      actions: 'Aktionen',
      addAttachment_title: 'Anhang hinzufügen',
      addComment: 'Kommentar hinzufügen',
      addManager_title: 'Manager hinzufügen',
      addMember_title: 'Mitglied hinzufügen',
      addUser_title: 'Benutzer hinzufügen',
      administrator: 'Administrator',
      all: 'Alle',
      allChangesWillBeAutomaticallySavedAfterConnectionRestored:
        'Alle Änderungen werden automatisch gespeichert, sobald die Verbindung wiederhergestellt wurde.',
      areYouSureYouWantToDeleteThisAttachment:
        'Sind Sie sicher, dass Sie diesen Anhang löschen möchten?',
      areYouSureYouWantToDeleteThisBoard: 'Sind Sie sicher, dass Sie dieses Board löschen möchten?',
      areYouSureYouWantToScheduleThisBoardForDeletion:
        'Sind Sie sicher, dass Sie dieses Board in den Papierkorb verschieben möchten? Boards im Papierkorb werden automatisch nach 14 Tagen gelöscht.',
      areYouSureYouWantToDeleteThisCard: 'Sind Sie sicher, dass Sie diese Kachel löschen möchten?',
      areYouSureYouWantToDeleteThisComment:
        'Sind Sie sicher, dass Sie diesen Kommentar löschen möchten?',
      areYouSureYouWantToDeleteThisLabel: 'Sind Sie sicher, dass Sie dieses Label löschen möchten?',
      areYouSureYouWantToDeleteThisList: 'Sind Sie sicher, dass Sie diese Spalte löschen möchten?',
      areYouSureYouWantToDeleteThisProject:
        'Sind Sie sicher, dass Sie dieses Projekt löschen möchten?',
      areYouSureYouWantToDeleteThisTask: 'Sind Sie sicher, dass Sie diese Aufgabe löschen möchten?',
      areYouSureYouWantToDeleteThisUser:
        'Sind Sie sicher, dass Sie diesen Benutzer löschen möchten?',
      areYouSureYouWantToLeaveBoard: 'Sind Sie sicher, dass Sie das Board verlassen möchten?',
      areYouSureYouWantToLeaveProject: 'Sind Sie sicher, dass Sie das Projekt verlassen möchten?',
      areYouSureYouWantToRemoveThisManagerFromProject:
        'Sind Sie sicher, dass Sie diesen Manager aus dem Projekt entfernen möchten?',
      areYouSureYouWantToRemoveThisMemberFromBoard:
        'Sind Sie sicher, dass Sie dieses Mitglied aus dem Board entfernen möchten?',
      attachment: 'Anhang',
      attachments: 'Anhänge',
      authentication: 'Authentifizierung',
      background: 'Hintergrund',
      board: 'Board',
      boardNotFound_title: 'Board nicht gefunden',
      boardAccess_title: 'Board-Zugriff',
      boardIsPublic: 'Board ist öffentlich',
      cardActions_title: 'Kachelaktionen',
      cardNotFound_title: 'Kachel nicht gefunden',
      cardOrActionAreDeleted: 'Kachel oder Aktion wurden gelöscht',
      color: 'Farbe',
      createBoard_title: 'Neues Board erstellen',
      createLabel_title: 'Label erstellen',
      createNewOneOrSelectExistingOne:
        'Erstellen Sie ein neues oder wählen Sie ein bestehendes aus.',
      createProject_title: 'Projekt erstellen',
      createTextFile_title: 'Textdatei erstellen',
      currentPassword: 'Derzeitiges Passwort',
      dangerZone_title: 'Gefährlicher Bereich',
      date: 'Datum',
      dueDate_title: 'Fälligkeitsdatum',
      deleteAttachment_title: 'Anhang löschen',
      deleteBoard_title: 'Board löschen',
      deleteCard_title: 'Kachel löschen',
      deleteComment_title: 'Kommentar löschen',
      deleteLabel_title: 'Label löschen',
      deleteList_title: 'Spalte löschen',
      deleteProject_title: 'Projekt löschen',
      deleteTask_title: 'Aufgabe löschen',
      deleteUser_title: 'Benutzer löschen',
      description: 'Beschreibung',
      detectAutomatically: 'Automatische Erkennung',
      dropFileToUpload: 'Datei ablegen, um sie hochzuladen',
      editAttachment_title: 'Anhang bearbieten',
      editAvatar_title: 'Avatar bearbeiten',
      editBoard_title: 'Board bearbeiten',
      editDueDate_title: 'Fälligkeitsdatum bearbeiten',
      editEmail_title: 'E-Mail-Adresse bearbeiten',
      editLabel_title: 'Label bearbeiten',
      editPassword_title: 'Passwort ändern',
      editStopwatch_title: 'Stoppuhr bearbeiten',
      editUsername_title: 'Benutzername ändern',
      email: 'E-Mail-Adresse',
      emailAlreadyInUse: 'E-Mail-Adresse bereits in Verwendung',
      enterCardTitle: 'Kacheltitel eingeben...',
      enterDescription: 'Beschreibung eingeben...',
      enterFilename: 'Dateiname eingeben',
      enterListTitle: 'Spaltentitel eingeben...',
      enterProjectTitle: 'Projekttitel eingeben',
      enterBoardTitle: 'Titel',
      enterTaskDescription: 'Aufgabenbeschreibung eingeben...',
      filterByLabels_title: 'Nach Label filtern',
      filterByMembers_title: 'Nach Mitgliedern filtern',
      fromComputer_title: 'Vom Computer',
      general: 'Allgemein',
      hours: 'Stunden',
      invalidCurrentPassword: 'Das aktuelle Passwort ist falsch',
      profession: 'Ausbildungsberuf',
      professions: 'Ausbildungsberufe',
      professionOrProfessions: 'Ausbildungsberuf(e)',
      labels: 'Labels',
      language: 'Sprache',
      leaveBoard_title: 'Board verlassen',
      leaveProject_title: 'Projekt verlassen',
      list: 'Spalten',
      login: 'Anmelden',
      managers: 'Manager',
      members: 'Mitglieder',
      minutes: 'Minuten',
      moveCard_title: 'Kachel verschieben',
      name: 'Name',
      newEmail: 'Neue E-Mail-Adresse',
      newPassword: 'Neues Passwort',
      newUsername: 'Neuer Benutzername',
      noConnectionToServer: 'Keine Verbindung zum Server',
      noBoards: 'Keine Boards gefunden',
      noLists: 'Keine Spalten',
      noProjects: 'Keine Projekte',
      notifications: 'Benachrichtigungen',
      noUnreadNotifications: 'Keine ungelesenen Benachrichtigungen',
      openBoard_title: 'Board öffnen',
      optional_inline: 'Optional',
      organization: 'Organisation',
      phone: 'Telefon',
      preferences: 'Voreinstellungen',
      pressPasteShortcutToAddAttachmentFromClipboard:
        'Tipp: Drücken Sie STRG-V (Cmd-V auf Mac), um einen Anhang aus der Zwischenablage hinzuzufügen',
      project: 'Projekt',
      projectNotFound_title: 'Projekt nicht gefunden',
      removeManager_title: 'Manager entfernen',
      removeMember_title: 'Mitglied entfernen',
      seconds: 'Sekunden',
      selectBoard: 'Board auswählen',
      selectList: 'Spalte auswählen',
      selectProject: 'Projekt auswählen',
      settings: 'Einstellungen',
      boardSettings: 'Board Einstellungen',
      stopwatch: 'Stoppuhr',
      subscribeToMyOwnCardsByDefault: 'Standardmäßig meine eigenen Kacheln abonnieren',
      taskActions_title: 'Aufgabenaktionen',
      tasks: 'Aufgaben',
      thereIsNoPreviewAvailableForThisAttachment: 'Für diesen Anhang ist keine Vorschau verfügbar',
      time: 'Zeit',
      title: 'Titel',
      subtitle: 'Untertitel',
      userActions_title: 'Benutzeraktionen',
      userAddedThisCardToList: '<0>{{user}}</0><1> hat diese Kachel hinzugefügt zu {{list}}</1>',
      userLeftNewCommentToCard:
        '{{user}} hat einen neuen Kommentar verfasst: «{{comment}}» in <2>{{card}}</2>',
      userMovedCardFromListToList:
        '{{user}} bewegte <2>{{card}}</2> von {{fromList}} nach {{toList}}',
      userMovedThisCardFromListToList:
        '<0>{{user}}</0><1> bewegte diese Kachel von {{fromList}} nach {{toList}}</1>',
      username: 'Benutzername',
      usernameAlreadyInUse: 'Benutzername wird bereits verwendet',
      users: 'Benutzer',
      writeAccessAddCards: 'Kacheln hinzufügen',
      writeAccess_anonymous_approved: 'Anonyme Nutzer/-innen mit Freigabe',
      writeAccess_anonymous_unapproved: 'Anonyme Nutzer/-innen ohne Freigabe',
      writeAccess_authorized_members_only: 'Nur schreibberechtigte Nutzer/-innen',
      writeComment: 'Kommentar verfassen...',
      myBoards: 'Meine Boards',
      discoverCommunityBoards: 'Entdecke Boards aus unserer Community',
      dataSecurity: 'Datenschutz',
      imprint: 'Impressum',
    },

    slideshow: {
      slideOf: '{{currentSlide}} von {{totalSlides}}',
    },

    action: {
      addAnotherCard: 'Eine weitere Kachel hinzufügen',
      addAnotherList: 'Spalte hinzufügen',
      addAnotherTask: 'Eine weitere Aufgabe hinzufügen',
      addCard: 'Kachel hinzufügen',
      addCard_title: 'Kachel hinzufügen',
      addComment: 'Kommentar hinzufügen',
      addList: 'Spalte hinzufügen',
      addMoreDetailedDescription: 'Eine detaillierte Beschreibung hinzufügen',
      addTask: 'Aufgabe hinzufügen',
      addToCard: 'Zu Kachel hinzufügen',
      addUser: 'Benutzer hinzufügen',
      createBoard: 'Board hinzufügen',
      loadMore: 'Mehr laden',
      loadMoreError: 'Weitere Boards konnten nicht geladen werden',
      createFile: 'Datei erstellen',
      createLabel: 'Label erstellen',
      createNewLabel: 'Neues Label erstellen',
      createProject: 'Projekt erstellen',
      delete: 'Löschen',
      deleteAttachment: 'Anhang löschen',
      deleteAvatar: 'Avatar löschen',
      deleteBoard: 'Board löschen',
      deleteCard: 'Kachel löschen',
      deleteCard_title: 'Kachel löschen',
      editCard: 'Kachel bearbeiten',
      deleteComment: 'Kommentar löschen',
      deleteImage: 'Bild löschen',
      deleteLabel: 'Label löschen',
      deleteList: 'Spalte löschen',
      deleteList_title: 'Spalte löschen',
      deleteProject: 'Projekt löschen',
      deleteProject_title: 'Projekt löschen',
      deleteTask: 'Aufgabe löschen',
      deleteTask_title: 'Aufgabe löschen',
      deleteUser: 'Benutzer löschen',
      edit: 'Bearbeiten',
      editDueDate_title: 'Fälligkeitsdatum bearbeiten',
      editDescription_title: 'Beschreibung ändern',
      editEmail_title: 'E-Mail-Adresse bearbeiten',
      editPassword_title: 'Passwort ändern',
      editStopwatch_title: 'Stoppuhr bearbeiten',
      editTitle_title: 'Titel bearbeiten',
      editUsername_title: 'Benutzername ändern',
      hideDetails: 'Details ausblenden',
      leaveBoard: 'Board verlassen',
      leaveProject: 'Projekt verlassen',
      logOut_title: 'Abmelden',
      makeCover_title: 'Als Vorschau festlegen',
      move: 'Verschieben',
      moveCard_title: 'Kachel bewegen',
      remove: 'Löschen',
      removeBackground: 'Hintergrund löschen',
      removeCover_title: 'Vorschau löschen',
      removeFromBoard: 'Vom Board entfernen',
      removeFromProject: 'Vom Projekt entfernen',
      removeManager: 'Manager entfernen',
      removeMember: 'Mitglied entfernen',
      save: 'Speichern',
      showAllAttachments: 'Alle Anhänge anzeigen ({{hidden}} versteckt)',
      showDetails: 'Details anzeigen',
      showFewerAttachments: 'Weniger Anhänge anzeigen',
      start: 'Start',
      stop: 'Stopp',
      subscribe: 'Abonnieren',
      unsubscribe: 'De-abonnieren',
      uploadNewAvatar: 'Neuen Avatar hochladen',
      uploadNewImage: 'Neues Bild hochladen',
      settings_title: 'Einstellungen',
      projectTrashSettings: 'Projekt Löschen',
      deleteProjectPermanently: 'Endgültig löschen',
      restoreProjectFromTrash: 'Wiederherstellen',
      rejectCard: 'Löschen',
      approveCard: 'Freigeben',
    },
  },
};
