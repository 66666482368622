import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Paths from '../../constants/Paths';
import { useSteps } from '../../hooks';
import ContextMenu from '../ContextMenu/ContextMenu';
import ContextMenuItem from '../ContextMenuItem/ContextMenuItem';
import DeleteStep from '../DeleteStep';

const StepTypes = {
  DELETE: 'DELETE',
};

const ActionsStep = React.memo(({ card, onDelete, onClose, canMarkAsReady, setReady }) => {
  const [t] = useTranslation();
  const [step, openStep, handleBack] = useSteps();

  const handleDeleteClick = useCallback(() => {
    openStep(StepTypes.DELETE);
  }, [openStep]);

  const navigate = useNavigate();

  const handleClickEdit = () => {
    navigate(Paths.CARDS.replace(':id', card.id));
    onClose();
  };

  if (step?.type === StepTypes.DELETE) {
    return (
      <DeleteStep
        title="common.deleteCard"
        content="common.areYouSureYouWantToDeleteThisCard"
        buttonContent="action.deleteCard"
        onConfirm={onDelete}
        onBack={handleBack}
      />
    );
  }

  const setReadyInternal = () => {
    setReady();
    onClose();
  };

  return (
    <ContextMenu>
      {canMarkAsReady && (
        <ContextMenuItem onClick={setReadyInternal}>{t('common.markCardReady')}</ContextMenuItem>
      )}
      <ContextMenuItem onClick={handleClickEdit}>
        {t('action.editCard', {
          context: 'title',
        })}
      </ContextMenuItem>
      <ContextMenuItem onClick={handleDeleteClick} isDelete>
        {t('action.deleteCard', {
          context: 'title',
        })}
      </ContextMenuItem>
    </ContextMenu>
  );
});

ActionsStep.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  card: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  setReady: PropTypes.func.isRequired,
  canMarkAsReady: PropTypes.bool.isRequired,
};

export default ActionsStep;
