/* eslint-disable react/require-default-props */
import React, { useCallback, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ModalContainer from '../../containers/ModalContainer';
import CardModalContent from '../CardModalContent/CardModalContent';
import FormError from '../FormError/FormError.js';

const isEmptyData = (data: {
  name: string;
  description: string;
  embedUrl: string;
  mediaUrl: string;
}) => {
  return !data.name && !data.description && !data.embedUrl && !data.mediaUrl;
};

const fallback = <FormError error="Kachel konnte  nicht geöffnet werden" />;

function CardModal({
  name,
  description,
  canEdit,
  onUpdate,
  isGuestCard,
  ready,
  onClose,
  error,
  embedUrl,
  onAttachmentCreate,
  onAttachmentDelete,
  mediaUrl,
  attachmentId,
  ogImage,
  ogTitle,
  ogDescription,
  isMediaUpdating,
  boardId,
  backgroundColor,
  backgroundColors,
  showCalendar,
  dateTime,
  showDuplicate,
  openCardDuplicateModal,
  mediaLinkUrl,
  mediaPreviewUrl,
  descriptionFocusViaTab,
  onDescriptionEditConfirmModalOpen,
  customImageUpload,
  handlePreviewFileSelect,
  handlePreviewFileDelete,
  previewImageId,
  boardWriteAccess,
  isCreator,
  defaultPreviewDeleted,
}: {
  name: string;
  description: string;
  canEdit: boolean;
  onUpdate: any;
  isGuestCard?: boolean;
  ready?: boolean;
  onClose: any;
  error: any;
  embedUrl?: string;
  onAttachmentCreate: ({ file }: { file: File | null }) => void;
  handlePreviewFileSelect: ({ file }: { file: File | null }) => void;
  handlePreviewFileDelete: (id: string) => void;
  onAttachmentDelete: (id: string) => void;
  mediaUrl: string;
  attachmentId: string;
  previewImageId: string;
  ogImage: string;
  ogTitle: string;
  ogDescription: string;
  isMediaUpdating: boolean;
  boardId: string;
  showCalendar: boolean;
  showDuplicate: boolean;
  backgroundColor: string;
  backgroundColors: readonly string[];
  dateTime: string;
  openCardDuplicateModal: () => void;
  mediaLinkUrl?: string;
  mediaPreviewUrl?: string;
  descriptionFocusViaTab?: boolean;
  onDescriptionEditConfirmModalOpen: () => void;
  customImageUpload: boolean;
  boardWriteAccess: string;
  isCreator?: boolean;
  defaultPreviewDeleted?: boolean;
}) {
  const [descriptionEditOpen, setDescriptionEditOpen] = useState(false);

  const openDescriptionEdit = () => {
    setDescriptionEditOpen(true);
  };

  const closeDescriptionEdit = () => {
    setDescriptionEditOpen(false);
  };

  const handleBackgroundColorUpdate = useCallback(
    (value) => {
      onUpdate({ backgroundColor: value });
    },
    [onUpdate],
  );
  const setDefaultPreviewDeleted = useCallback(
    (value) => {
      onUpdate({ defaultPreviewDeleted: value });
    },
    [onUpdate],
  );

  const isEmpty = isEmptyData({ name, description, embedUrl, mediaUrl });

  const handleNameUpdate = useCallback(
    (newName) => {
      onUpdate({ name: newName });
    },
    [onUpdate],
  );

  const handleDescriptionUpdate = useCallback(
    (newDescription) => {
      onUpdate({ description: newDescription });
    },
    [onUpdate],
  );

  const wrappedHandlePreviewFileSelect = useCallback(
    (options: { file: File | null }) => {
      setDefaultPreviewDeleted(false);
      handlePreviewFileSelect(options);
    },
    [handlePreviewFileSelect, setDefaultPreviewDeleted],
  );

  const handleClose = useCallback(() => {
    if (descriptionEditOpen) {
      onDescriptionEditConfirmModalOpen();
      return;
    }
    onClose({ name, description, embedUrl, mediaUrl, boardId });
  }, [
    onClose,
    name,
    description,
    embedUrl,
    mediaUrl,
    boardId,
    onDescriptionEditConfirmModalOpen,
    descriptionEditOpen,
  ]);

  const setEmbedUrl = useCallback(
    (embedUrl) => {
      onUpdate({ embedUrl });
    },
    [onUpdate],
  );

  const handleMediaDelete = useCallback(() => {
    if (attachmentId) {
      onAttachmentDelete(attachmentId);
    }
    if (previewImageId) {
      handlePreviewFileDelete(previewImageId);
    }
    setDefaultPreviewDeleted(true);
  }, [
    attachmentId,
    // embedUrl,
    handlePreviewFileDelete,
    onAttachmentDelete,
    previewImageId,
    setDefaultPreviewDeleted,
    // setEmbedUrl,
  ]);

  const handlePreviewImageDelete = useCallback(() => {
    if (!previewImageId) {
      return;
    }
    handlePreviewFileDelete(previewImageId);
  }, [handlePreviewFileDelete, previewImageId]);

  const handleMediaUpdate = useCallback(
    (data) => {
      onAttachmentCreate(data);
      setDefaultPreviewDeleted(false);
    },

    [setDefaultPreviewDeleted, onAttachmentCreate],
  );

  const setDateTime = useCallback(
    (value) => {
      let timestamp = null;

      if (value === '') {
        timestamp = 0;
      }
      if (value) {
        timestamp = new Date(value).getTime();
      }

      onUpdate({ scheduledToBeVisibleAt: timestamp });
    },
    [onUpdate],
  );

  const setReady = useCallback(() => {
    onUpdate({ ready: true });
    handleClose();
  }, [onUpdate, handleClose]);

  return (
    <ModalContainer basic open onClose={handleClose}>
      <ErrorBoundary fallback={fallback}>
        <CardModalContent
          name={name}
          description={description}
          canEdit={canEdit}
          error={error}
          handleNameUpdate={handleNameUpdate}
          handleDescriptionUpdate={handleDescriptionUpdate}
          embedUrl={embedUrl}
          setEmbedUrl={setEmbedUrl}
          onMediaDelete={handleMediaDelete}
          mediaUrl={mediaUrl}
          isMediaUpdating={isMediaUpdating}
          onMediaUpdate={handleMediaUpdate}
          ogImage={ogImage}
          ogTitle={ogTitle}
          ogDescription={ogDescription}
          isEmpty={isEmpty}
          backgroundColor={backgroundColor}
          setBackgroundColor={handleBackgroundColorUpdate}
          backgroundColors={backgroundColors}
          showCalendar={showCalendar}
          dateTime={dateTime}
          setDateTime={setDateTime}
          showDuplicate={showDuplicate}
          openCardDuplicateModal={openCardDuplicateModal}
          mediaLinkUrl={mediaLinkUrl}
          mediaPreviewUrl={mediaPreviewUrl}
          descriptionFocusViaTab={descriptionFocusViaTab}
          descriptionEditOpen={descriptionEditOpen}
          openDescriptionEdit={openDescriptionEdit}
          closeDescriptionEdit={closeDescriptionEdit}
          customImageUpload={customImageUpload}
          handlePreviewFileSelect={wrappedHandlePreviewFileSelect}
          handlePreviewFileDelete={handlePreviewImageDelete}
          isGuestCard={isGuestCard}
          ready={ready}
          setReady={setReady}
          boardWriteAccess={boardWriteAccess}
          isCreator={isCreator}
          defaultPreviewDeleted={defaultPreviewDeleted}
          setDefaultPreviewDeleted={setDefaultPreviewDeleted}
        />
      </ErrorBoundary>
    </ModalContainer>
  );
}

export default React.memo(CardModal);
