import DefaultMediaPreviewContainer from '../../containers/MediaPreviewContainer';
import MediaUploadFilePicker from '../MediaUploadFilePicker/MediaUploadFilePicker';
import MediaUploadLoader from '../MediaUploadLoader/MediaUploadLoader';
import MediaUploadPreview from '../MediaUploadPreview/MediaUploadPreview';

function MediaUpload({
  handleFileSelect,
  handleFileDelete,
  media,
  isMediaUpdating,
  canEdit,
  mediaPreviewUrl,
  mediaLinkUrl,
  MediaPreviewContainer = DefaultMediaPreviewContainer,
  customImageUpload,
  isImage,
  handlePreviewFileSelect,
  handlePreviewFileDelete,
  setDefaultPreviewDeleted,
  defaultPreviewDeleted,
  isBoardImage,
}: {
  handleFileSelect: ({ file }: { file: File }) => void;
  handlePreviewFileSelect: ({ file }: { file: File }) => void;
  handlePreviewFileDelete: () => void;
  handleFileDelete: () => void;
  media: string;
  isMediaUpdating: boolean;
  canEdit: boolean;
  mediaPreviewUrl?: string;
  mediaLinkUrl?: string;
  MediaPreviewContainer?: any;
  customImageUpload?: boolean;
  isBoardImage?: boolean;
  isImage?: boolean;
  setDefaultPreviewDeleted: (value: boolean) => void;
  defaultPreviewDeleted: boolean;
}) {
  if (isMediaUpdating) {
    return <MediaUploadLoader />;
  }
  if (media) {
    return (
      <MediaUploadPreview
        mediaLinkUrl={mediaLinkUrl}
        mediaPreviewUrl={mediaPreviewUrl}
        media={media}
        handleFileDelete={handleFileDelete}
        canEdit={canEdit}
        MediaPreviewContainer={MediaPreviewContainer}
        customImageUpload={customImageUpload}
        handlePreviewFileSelect={handlePreviewFileSelect}
        handlePreviewFileDelete={handlePreviewFileDelete}
        defaultPreviewDeleted={defaultPreviewDeleted}
        setDefaultPreviewDeleted={setDefaultPreviewDeleted}
      />
    );
  }
  if (!canEdit) {
    return null;
  }
  return (
    <MediaUploadFilePicker
      handleFileSelect={handleFileSelect}
      isImage={isImage}
      isBoardImage={isBoardImage}
    />
  );
}

export default MediaUpload;
