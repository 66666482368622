import { call, select } from 'redux-saga/effects';
import * as selectors from '../../../../selectors';

const reload = () => {
  window.location.reload();
};

export function* handleProjectPasswordChange(changedId) {
  const { projectId } = yield select(selectors.selectPath);

  if (changedId === projectId) {
    // TODO don't reload if it is admin or invited user
    yield call(reload);
  }
}
